



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'source-code-pro', 'Menlo, Monaco', 'Consolas', 'Courier New',
    monospace;
}
body::-webkit-scrollbar-track{
  display: none;
  background-color: 'secondary';
}
body::-webkit-scrollbar{
  width: 5px;
  background: 'secondary';
}
body::-webkit-scrollbar-track:hover{
  width: 5px;
  display: block;
  background: 'secondary';
}
body::-webkit-scrollbar-thumb{
  background: #FFF;
}
body::-webkit-scrollbar-thumb{
  display: none;
}
body::-webkit-scrollbar-thumb:hover{
  display: block;
  background: #FFF;
}
body::-webkit-scrollbar-track-piece{ 
  background: 'secondary';
}